import {
    Button,
    Box,
  } from "@mui/material";


const onClick = () => {
    document.location.href = "/memory-game";
    console.log("Play Memory Game Clicked");
};

const MemoryGameInfo = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h3>How to Play</h3>
        <p><strong>Objective:</strong> Quickly calculate the difference between consecutive numbers and enter your answer correctly.</p>
        <ul>
            <li><strong>Starting Number:</strong> The game begins with a <strong>single-digit number (0-9)</strong> displayed on the screen. Memorize this number and press any key to continue!</li>
            <li><strong>New Number Appears:</strong> A second <strong>single-digit number</strong> replaces the first.</li>
            <ul>
                <li>Your task: Calculate the <strong>absolute difference</strong> between the two numbers and enter it (the difference will always be between 0-9)</li>
                <li><em>Example:</em> If the first number is <strong>7</strong> and the new number is <strong>3</strong>, the difference is <strong>4</strong> (7 - 3).</li>
            </ul>
            <li><strong>Enter the Difference:</strong> Type the correct difference using your keyboard. Once you press the number, the next number appears.</li>
            <li><strong>Repeat for 10 Rounds:</strong> Continue comparing the new number to the previous one for 10 total rounds.</li>
        </ul>
        <h3>Example Round:</h3>
        <ul>
            <li>Displayed Number: <strong>6</strong></li>
            <li>New Number: <strong>2</strong> → You enter <strong>4</strong> (6 - 2)</li>
            <li>New Number: <strong>9</strong> → You enter <strong>7</strong> (9 - 2)</li>
            <li>New Number: <strong>5</strong> → You enter <strong>4</strong> (9 - 5)</li>
        </ul>
        <h3>Tips for Success:</h3>
        <ul>
            <li>✅ <strong>Say it loud</strong> – Keep track of the last number by saying it out loud during the number change.</li>
            <li>✅ <strong>Use the numpad</strong> – Keep your finger on the last number so you don't lose track of it when the number changes.</li>
        </ul>
        <h3>🔥 Are you ready? Let’s test your memory skills! 🚀</h3>
      <Box sx={{ textAlign: "center", marginTop: "1em" }}>
            <Button variant="outlined" onClick={onClick} color="inherit">
                {"Lets Play"}
            </Button>
        </Box>
    </div>
  );
};
export default MemoryGameInfo;
