import React, { useState } from 'react';
import VariableGameRounds from './VariableGameRounds'
import { postScore } from '../api/postScore';


const VariableGame: React.FC = () => {
    const [currentRound, setCurrentRound] = useState<number>(1);
    const [totalScore, setTotalScore] = useState<number>(0);
    const [gameFinished, setGameFinished] = useState<boolean>(false);

    const handleRoundCompletion = (score: number) => {
        setTotalScore((prev) => prev + score);
        console.log("score " + score)
        console.log("total score " + totalScore)
        if (currentRound < 10) {
            setCurrentRound((prev) => prev + 1);
        } else {
            setGameFinished(true);
            postScore(totalScore, 30, "variable")
        }
    };

    return (
        <div 
            id="numberDisplay" 
            style={{ 
                fontSize: '28px', 
                fontWeight: 'bold', 
                textAlign: 'center', 
                marginTop: '20vh' 
            }}
        >
            <h2>Memory Test: Round {currentRound} / 10</h2>
            {!gameFinished ? (
                <VariableGameRounds key={currentRound} onGameEnd={handleRoundCompletion} />
            ) : (
                <h2>Final Score: {totalScore} / 30</h2>
            )}
        </div>
    );
};

export default VariableGame;
