import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
  } from "@mui/material";

interface VariableValues {
    A: number;
    B: number;
    C: number;
}

interface VariableMemoryGameProps {
    onGameEnd: (score: number, values: VariableValues) => void;
}

function getRandomValues(): VariableValues {
    return {
        A: Math.floor(Math.random() * 9) + 1,
        B: Math.floor(Math.random() * 9) + 1,
        C: Math.floor(Math.random() * 9) + 1,
    };
}

function shuffleArray<T>(array: T[]): T[] {
    return array.sort(() => Math.random() - 0.5);
}

const VariableGameRounds: React.FC<VariableMemoryGameProps> = ({ onGameEnd }) => {
    const [values] = useState<VariableValues>(getRandomValues());
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [showQuestion, setShowQuestion] = useState<boolean>(false);
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [gameStarted, setGameStarted] = useState<boolean>(false);
    const [gameFinished, setGameFinished] = useState<boolean>(false);
    const [shuffledVariables, setShuffledVariables] = useState<(keyof VariableValues)[]>(shuffleArray(['A', 'B', 'C']));

    useEffect(() => {
        if (gameStarted && currentStep < 3) {
            const timer = setTimeout(() => {
                setCurrentStep((prev) => prev + 1);
            }, 1500);
            return () => clearTimeout(timer);
        } else if (gameStarted && currentStep === 3) {
            setTimeout(() => {
                setShowQuestion(true);
            }, 1000);
        }
    }, [gameStarted, currentStep]);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (showQuestion && event.key >= '1' && event.key <= '9') {
                const currentVariable = shuffledVariables[questionIndex];
                console.log(`Key pressed: ${event.key}, Expected: ${values[currentVariable]}`);
                
                setScore((prevScore) => {
                    return parseInt(event.key, 10) === values[currentVariable] ? prevScore + 1 : prevScore;
                });

                if (questionIndex + 1 >= shuffledVariables.length) {
                    setShowQuestion(false);
                    setGameFinished(true);
                    setGameStarted(false);
                    setTimeout(() => onGameEnd(score + (parseInt(event.key, 10) === values[currentVariable] ? 1 : 0), values), 0);
                } else {
                    setQuestionIndex((prev) => prev + 1);
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [questionIndex, values, showQuestion]);

    const startGame = () => {
        setGameStarted(true);
        setGameFinished(false);
        setCurrentStep(0);
        setShowQuestion(false);
        setQuestionIndex(0);
        setScore(0);
        setShuffledVariables(shuffleArray(['A', 'B', 'C']));
    };

    return (
        <div 
            id="numberDisplay" 
            style={{ 
                fontSize: '48px', 
                fontWeight: 'bold', 
                textAlign: 'center', 
                marginTop: '20vh' 
            }}
        >
            {gameStarted && currentStep === 0 && <p>A = {values.A}</p>}
            {gameStarted && currentStep === 1 && <p>B = {values.B}</p>}
            {gameStarted && currentStep === 2 && <p>C = {values.C}</p>}
            {!gameStarted && !gameFinished ? (
                <Box sx={{ textAlign: "center", marginTop: "1em" }}>
                    <Button variant="outlined" onClick={startGame} color="inherit">
                        {"Lets Play"}
                    </Button>
                </Box>
            ) : showQuestion && questionIndex < shuffledVariables.length ? (
                <h3>{shuffledVariables[questionIndex]} = ?</h3>
            ) : null}
        </div>
    );
};

export default VariableGameRounds;
