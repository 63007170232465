import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { CustomThemeProvider } from "./theme";
import { CssBaseline } from "@mui/material";
import { IntercomProvider, useIntercom } from "react-use-intercom";

import Home from "./pages/Home";

import SignUpFlowWrapper from "./components/signup/SignUpFlowWrapper";
import MemoryGame from "./pages/MemoryGame";
import VariableGame from "./pages/VariableGame";
//import RequestLogin from "./components/signin/RequestLogin";
//import VerifyOtp from './components/signin/VerifyOTP';

// Placeholder Components
//const Home = () => <div>Home Page</div>;

const INTERCOM_APP_ID = "aiyjvdf0";

function App() {
  const token = localStorage.getItem("atcRefresh");

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <CustomThemeProvider>
        <CssBaseline />
        <Router>
          <Routes>
            <Route
              path="*"
              element={token ? <Navigate to="/home" /> : <Navigate to="/" />}
            />
            <Route
              path="/home"
              element={token ? <Home /> : <SignUpFlowWrapper />}
            />
            <Route
              path="/memory-game"
              element={token ? <MemoryGame /> : <SignUpFlowWrapper />}
            />
            <Route
              path="/variable-game"
              element={token ? <VariableGame /> : <SignUpFlowWrapper />}
            />
            <Route
              path="/"
              element={token ? <Home /> : <SignUpFlowWrapper />}
            />
          </Routes>
        </Router>
      </CustomThemeProvider>
    </IntercomProvider>
  );
}

export default App;
