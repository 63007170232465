import {
    Button,
    Box,
  } from "@mui/material";


const onClick = () => {
    document.location.href = "/variable-game";
    console.log("Play Variable Game Clicked");
};

const VariableGameInfo = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
        <h3>How to Play</h3>

        <p><strong>Objective:</strong> Memorize the values assigned to <strong>A, B, and C</strong> and correctly recall them when prompted.</p>

        <h4>Game Flow:</h4>
        <ul>
            <li>The game will display three variables <strong>A, B, and C</strong>, each assigned a <strong>random number between 1 and 9</strong>.</li>
            <li>You will see the values for <strong>A, B, and C</strong> one by one.</li>
            <ul>
                <li><em>Example:</em></li>
                <li>A = <strong>3</strong> (displayed first)</li>
                <li>B = <strong>7</strong> (displayed next)</li>
                <li>C = <strong>2</strong> (displayed last)</li>
            </ul>
            <li>After all values are shown, the screen will clear, and you will be prompted with a random variable.</li>
            <li><strong>Your task:</strong> Enter the correct number that was previously assigned to the prompted variable.</li>
            <ul>
                <li><em>Example:</em></li>
                <li>The screen asks: <strong>"B = ?"</strong></li>
                <li>You type <strong>7</strong> (since B was assigned 7).</li>
            </ul>
            <li>You will repeat this for <strong>all three variables in a random order</strong>.</li>
        </ul>

        <h4>Example Round:</h4>
        <ul>
            <li><strong>Displayed Values (one at a time)</strong></li>
            <ul>
                <li>A = <strong>6</strong></li>
                <li>B = <strong>3</strong></li>
                <li>C = <strong>9</strong></li>
            </ul>
            <li><strong>Question Phase:</strong></li>
            <ul>
                <li>The screen asks: <strong>"C = ?"</strong> → You enter <strong>9</strong></li>
                <li>The screen asks: <strong>"A = ?"</strong> → You enter <strong>6</strong></li>
                <li>The screen asks: <strong>"B = ?"</strong> → You enter <strong>3</strong></li>
            </ul>
            <li><strong>Your score is calculated based on correct answers.</strong></li>
        </ul>

        <h4>Game Rounds</h4>
        <ul>
            <li>The game consists of <strong>10 rounds</strong>.</li>
            <li>At the end of 10 rounds, your <strong>total score</strong> is displayed.</li>
        </ul>

        <h4>Tips for Success:</h4>
        <ul>
            <li>✅ <strong>Focus on the order</strong> – Try to group the values into a short memory trick.</li>
            <li>✅ <strong>Say it aloud</strong> – Repeating the values while they appear can help reinforce memory.</li>
            <li>✅ <strong>Stay focused</strong> – The values disappear quickly, so don’t let distractions break your concentration.</li>
        </ul>

        <h3>🔥 Are you ready to test your memory? 🚀</h3>

      <Box sx={{ textAlign: "center", marginTop: "1em" }}>
            <Button variant="outlined" onClick={onClick} color="inherit">
                {"Lets Play"}
            </Button>
        </Box>
    </div>
  );
};
export default VariableGameInfo;
