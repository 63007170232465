import React, { useState, useEffect } from 'react';
import { postScore } from '../api/postScore';
import {
    Button,
    Box,
  } from "@mui/material";

function getRandomNumber(exclude: number | null): number {
    let newNumber;
    do {
        newNumber = Math.floor(Math.random() * 9) + 1;
    } while (newNumber === exclude);
    return newNumber;
}

type KeyDownEvent = KeyboardEvent;

const tryAgain = () => {
    document.location.href = "/memory-game";
    console.log("Play Memory Game Clicked");
};

const goHome = () => {
    document.location.href = "/home";
    console.log("Home Clicked");
};

function MemoryGame(): JSX.Element {
    const [number, setNumber] = useState<number>(getRandomNumber(null));
    const [previousNumber, setPreviousNumber] = useState<number | null>(null);
    const [differences, setDifferences] = useState<number[]>([]);
    const [matches, setMatches] = useState<number>(0);
    const [roundComplete, setRoundComplete] = useState<boolean>(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyDownEvent): void => {
            if (event.key === ' ') {
                console.log(`Spacebar pressed, updating first number.`);
                setPreviousNumber(number);
                setNumber(getRandomNumber(number));
                setDifferences([]);
                setMatches(0);
                setRoundComplete(false);
            } else if (event.key >= '1' && event.key <= '9') {
                const keyNumber = parseInt(event.key, 10);
                console.log(`Key pressed: ${event.key}`);

                if (previousNumber !== null && differences.length < 10) {
                    const difference = Math.abs(number - previousNumber);
                    console.log(`Difference between numbers: ${difference}`);
                    setDifferences([...differences, difference]);

                    if (difference === keyNumber) {
                        console.log(`Difference matches the key pressed!`);
                        setMatches(matches + 1);
                    }
                }

                if (differences.length === 9) {
                    console.log(`Round complete. Matches: ${matches} out of 10.`);
                    setRoundComplete(true);

                    // Use postScore
                    console.log(matches)
                    postScore(matches, 10, "memory")
                    .then((data) => console.log('Score successfully sent:', data))
                    .catch((error) => console.error('Error sending score:', error));
                }

                setPreviousNumber(number);
                setNumber(getRandomNumber(number));
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [number, previousNumber, differences, matches]);

    return (
        <div 
            id="numberDisplay" 
            style={{ 
                fontSize: '48px', 
                fontWeight: 'bold', 
                textAlign: 'center', 
                marginTop: '20vh' 
            }}
        >
            {!roundComplete && number}
            {roundComplete && (
                <div 
                    style={{ 
                        marginTop: '20px', 
                        fontSize: '32px', 
                        color: 'green' 
                    }}
                >
                    Matches: {matches} out of 10
                    <Box sx={{ textAlign: "center", marginTop: "1em", display: "flex", justifyContent: "center", gap: "1em" }}>
                        <Button variant="outlined" onClick={tryAgain} color="inherit">
                          {"Try Again"}
                        </Button>
                        <Button variant="outlined" onClick={goHome} color="inherit">
                          {"Home"}
                        </Button>
                    </Box>          
                </div>
            )}
        </div>
    );
}

export default MemoryGame;
