import Profile from "../pages/Profile";
import FAQ from "../pages/FAQ";
import MemoryGameInfo from "../pages/MemoryGameInfo"
import VariableGameInfo from "../pages/VariableGameInfo";

const content = [
  { title: "AT-SA Practice", componenet: null, path: '/simulator'},
  { title: "FAQ", component: <FAQ /> },
  { title: "Profile", component: <Profile /> },
  { title: "Memory Game", component:<MemoryGameInfo/>},
  { title: "Variable Game", component:<VariableGameInfo/>}
];

export default content;
