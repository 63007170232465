import { apiClient } from './apiClient';

/**
 * Sends the score to the POST endpoint.
 * @param {number} scoreObtained - The score of the match.
 * @param {number} scoreTotal - The best possible score.
 * @param {string} gameType - The type of game.
 * @returns {Promise<any>} - The response from the server.
 */
export async function postScore(scoreObtained: number, scoreTotal: number, gameType: string): Promise<any> {
    let url_prefix = "https://roger-that-e17b66fe09c9.herokuapp.com/";
    if (process.env.NODE_ENV === "development") {
        url_prefix = "http://127.0.0.1:8000/";
    }
    var url = url_prefix + "apiapp/memory-score/"; // Update with correct base URL if needed
    const body = {
        "score_obtained": scoreObtained,
        "score_total": scoreTotal,
        "game_type": gameType,
    };

    try {
        console.log(gameType)
        const response = await apiClient(url, body, 'POST');
        if (response) {
            console.log('Score successfully sent:', response);
        }
        return response;
    } catch (error) {
        console.error('Error posting score:', error);
        throw error;
    }
}